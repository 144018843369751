import React from 'react';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';


const Home = () => {
  return (
    <div className='home'>
        <Navbar></Navbar>
        <div className='home__box'>
           <img src='./images/card1.png' alt='card' className='home__img'></img>
           <img src='./images/card2.png' alt='card' className='home__img'></img>
           <img src='./images/card3.png' alt='card' className='home__img'></img>
           <img src='./images/card4.png' alt='card' className='home__img'></img>
        </div>
        <Footer></Footer>
    </div>
  )
}

export default Home
import React from 'react'
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className='navbar'>
    <div className='navbar__box'>
     <a href="/" className="navbar__logo-link">
       <img src="images/Logo.png" alt="MyCompany Logo - Visa Service" className="navbar__logo" />
     </a>
     <ul className="navbar__menu" aria-label="Main navigation">
         <li className="navbar__menu-item"><Link to="/termsAndConditions" className='navbar__menu-link'>
            Terms and Conditions
          </Link></li>
          <li className="navbar__menu-item"><Link to="/cancellationAndRefundPolicy" className='navbar__menu-link'>
            Refund And Cancellation
          </Link></li>
          <li className="navbar__menu-item"><Link to="/contactUs" className='navbar__menu-link'>
            Contact Us
          </Link></li>
        
     </ul>
     </div>
     </nav>
  )
}

export default Navbar
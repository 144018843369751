import './App.scss';
import { Routes, Route } from "react-router-dom";
import Home from './screens/Home';
import TermsAndConditions from './screens/TermsAndConditions';
import CancellationAndRefundPolicy from './screens/CancellationAndRefundPolicy';
import ContactUs from './screens/ContactUs';

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route
            index
            element={
                    <Home/>
            }
        />
      </Route>
      <Route path="/termsAndConditions">
        <Route
            index
            element={
                    <TermsAndConditions/>
            }
        />
      </Route>
      <Route path="/cancellationAndRefundPolicy">
        <Route
            index
            element={
                    <CancellationAndRefundPolicy/>
            }
        />
      </Route>
      <Route path="/contactUs">
        <Route
            index
            element={
                    <ContactUs/>
            }
        />
      </Route>
    </Routes>
  );
}

export default App;

import React from "react";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";

const CancellationAndRefundPolicy = () => {
  return (
    <div className="policy">
      <Navbar></Navbar>
      <h1 className="policy__title">Cancellation and Refund Policy</h1>

      <p className="policy__intro">
        At VisaFu, we strive to offer the best service experience. However, due
        to the nature of our business, certain cancellations and refund
        conditions apply. By using our services, you agree to the following
        terms:
      </p>

      <section className="policy__section">
        <h2 className="policy__heading">1. Order Cancellation</h2>
        <p className="policy__text">
          1.1. VisaFu reserves the right to refuse or cancel Your application
          under specific circumstances, which may include but are not limited
          to:
        </p>
        <ul className="policy__list">
          <li className="policy__list-item">
            ~ Downtime or unavailability of Embassy or third-party systems.
          </li>
          <li className="policy__list-item">
            ~ Unforeseen circumstances beyond our control.
          </li>
          <li className="policy__list-item">
            ~ Incomplete or incorrect information provided by You, such as wrong
            travel dates, unclear passport copies, or failure to respond to our
            communication within 24 hours of receipt.
          </li>
        </ul>
        <p className="policy__text">
          1.2. VisaFu reserves the right to refuse or cancel Your application if
          any fraudulent, unauthorized, or illegal transaction is suspected.
        </p>
      </section>

      <section className="policy__section">
        <h2 className="policy__heading">2. No Refund Policy</h2>
        <p className="policy__text">
          2.1. VisaFu operates a strict NO REFUND policy. Once the service has
          been rendered or your application has been processed, VisaFu will not
          issue a refund, regardless of the outcome of your visa application.
        </p>
        <p className="policy__text">
          2.2. If the Embassy or Consulate rejects your visa application, VisaFu
          will not be liable to offer any refund for the service fees or visa
          fees.
        </p>
      </section>

      <section className="policy__section">
        <h2 className="policy__heading">3. Liability for Overstay</h2>
        <p className="policy__text">
          3.1. In the event that You overstay at your destination without proper
          approvals, You will be responsible for any penalties, legal actions,
          or disciplinary measures imposed by the government or relevant
          authorities. VisaFu will bear no responsibility in such cases.
        </p>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default CancellationAndRefundPolicy;

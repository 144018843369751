import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__logo-container">
        <img src="./images/Logo.png" alt="VisaFu Logo" className="footer__logo" />
      </div>
      <ul className="footer__links">
        <li className="footer__link-item">
          <Link to="/termsAndConditions" className="footer__link">
            Terms and Conditions
          </Link>
        </li>
        <li className="footer__link-item">
          <Link to="/cancellationAndRefundPolicy" className="footer__link">
            Refund and Cancellation
          </Link>
        </li>
        <li className="footer__link-item">
          <Link to="/contactUs" className="footer__link">
            Contact Us
          </Link>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
